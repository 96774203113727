<template>
  <card :full-height="true" :no-padding="true" class="py-3 bg-transparent shadow-none">
    <card-header title="Leads" class="d-none"></card-header>
    <data-table-basic id="dashboard_leads_table">
      <thead>
      <tb-row class="nk-tb-head">
        <tb-th>#ID</tb-th>
        <tb-th>Name</tb-th>
        <tb-th>Another</tb-th>
        <tb-th>Other</tb-th>
        <tb-th>Status</tb-th>
        <tb-th>actions</tb-th>
      </tb-row>
      </thead>
      <tbody>
        <tb-row v-for="(lead, index) in leadsData" :key="index">
          <tb-td size="sm">
            <strong class="text-success">#{{ lead.lead_number }}</strong>
            <span class="d-block text-muted small font-weight-bold">{{ lead.received_date }}</span>
            <span class="d-block text-muted small font-weight-bold">{{ lead.received_time }}</span>
          </tb-td>
          <tb-td>
            <user-small-card :user-name="lead.first_name+' '+lead.last_name" initials="S" :user-email="lead.email">
              <span class="d-block small">{{ lead.form_name }}</span>
            </user-small-card>
          </tb-td>
          <tb-td class="text-muted">
            <span>{{ lead.city}}, {{ lead.province }}</span>
            <span class="d-block">{{ lead.post_code }}</span>
          </tb-td>
          <tb-td class="text-muted">
            &euro;{{ lead.income }}
          </tb-td>
          <tb-td>
            <badge content="" :dim="true" :outline="true" :type="badge_type(lead.status_id)" class="flex-column">
              <template v-if="lead.event_date.length > 0">
                <span v-if="lead.event_type === 'followup'">{{ $t('Follow-up on') }}:</span>
                <span v-else-if="lead.event_type === 'event'">{{ $t('Appointment on') }}:</span>
                <span class="d-block text-muted">{{ lead.event_date+' '+$t('at')}} {{ lead.event_time }} </span>
              </template>
              <template v-else>{{ lead.status_text }}</template>
            </badge>
          </tb-td>
          <tb-td>
            <tb-actions class="justify-start">
              <li>
                <nk-button size="sm" type="success" :is-icon-only-button="true">
                  <nio-icon icon="ni-call"></nio-icon>
                </nk-button>
              </li>
              <li>
                <nk-button size="sm" type="success" :is-icon-only-button="true">
                  <nio-icon icon="ni-mail"></nio-icon>
                </nk-button>
              </li>
              <li>
                <drop-down-list>
                  <li>
                    <router-link :to="{name: 'LeadDetails', params:{ id: lead.id}}">
                      <nio-icon icon="ni-card-view"></nio-icon> <span>View Details</span>
                    </router-link>
                  </li>
                </drop-down-list>
              </li>
            </tb-actions>
          </tb-td>
        </tb-row>
      </tbody>
    </data-table-basic>
  </card>
</template>

<script>
import {Card, CardHeader } from "@core/components/cards";
import DataTableBasic from "@core/components/data-tables/DataTableBasic";
import { TbRow, TbTh, TbTd, TbActions } from '@core/components/data-tables'
import UserSmallCard from "@/views/components/user-cards/UserSmallCard";
import Badge from "@core/components/badge/Badge";
import { DropDownList } from "@core/components"
import NioIcon from "@core/components/nio-icon/NioIcon";

export default {
  components: {
    NioIcon,
    DropDownList,
    TbActions,
    Badge,
    TbTh,
    UserSmallCard,
    TbTd,
    TbRow,
    Card,
    CardHeader,
    DataTableBasic,
  },
  props: {
    leadsData: Array,
  },
  methods:{
    badge_type(lead_status_id){
      let t = 'success'
      if(lead_status_id === '1'){
        t = 'info'
      }else if( lead_status_id === '2'){
        t = 'warning'
      }
      else if( lead_status_id === '3'){
        t = 'danger'
      }
      return t
    }
  },
}
</script>
