<template>
  <ion-page>
    <ion-content :fullscreen="true" class="bg-lighter ion-content">
      <vertical-nav-menu></vertical-nav-menu>
      <div class="nk-wrap">
        <nav-bar></nav-bar>
        <main-block>
          <block-content>
            <row class="g-gs">
              <column :xxl="6">
                <user-card
                    :user_data="store.state.advisor_data"
                    :total_leads="dashboard_data.counts.this_week.total_leads"
                    :avail_leads="dashboard_data.counts.this_week.avail_leads"></user-card>
              </column>

              <column :xxl="3" :md="6">
                <card :full-height="true" no-padding id="home_notifications_card">
                  <card-header title="Notification" class="p-2 border-bottom">
                    <badge type="success" pill="true" dim :content="store.state.notifications.unread_count +' new'" v-if="store.state.notifications.unread_count > 0"></badge>
                  </card-header>
                  <notification-items :limit="5"></notification-items>
                  <div class="p-2 border-top" v-if="store.state.notifications.notifications.length > 5">
                    <nk-button type="success" outline size="sm" full-width="true" dim v-on:click="router.push({name:'Notifications'})">Read all notifications</nk-button>
                  </div>
                </card>
              </column>

              <column :xxl="3" :md="6">
                <card :full-height="true" :no-padding="true" class="pb-4">
                  <card-header title="Afspraken per status" class="p-2">
                    <span class="text-light">Last 7 Days</span>
                  </card-header>
                  <chartjs-doughnut
                      :data="dashboard_data.pie_chart.values"
                      :labels="dashboard_data.pie_chart.labels"
                      :colors="dashboard_data.pie_chart.colors"
                      data-unit="Leads">

                  </chartjs-doughnut>
                </card>
              </column>

              <column :xxl="12">
                <dash-board-leads :leads-data="dashboard_data.leads"></dash-board-leads>
              </column>
            </row>
          </block-content>
        </main-block>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage, /*IonHeader, IonTitle, IonToolbar*/ } from '@ionic/vue';
import {defineComponent, nextTick, reactive, /*nextTick*/} from 'vue';
//import axios from "@/libs/axios";
//import card from "@/layouts/components/card";
import VerticalNavMenu from "@/@core/layouts/vertical-nav-menu/VerticalNavMenu";
import NavBar from "@core/layouts/app-nav-bar/NavBar";
import MainBlock from "@core/layouts/main-block/MainBlock";
import BlockContent from "@core/layouts/main-block/components/BlockContent";
import { Row, Column } from '@core/layouts';
import { Card, CardHeader } from '@core/components/cards'
import NotificationItems from "@core/layouts/app-nav-bar/components/NotificationItems";
import Badge from "@core/components/badge/Badge";
import NkButton from "@core/components/button/NkButton";
import ChartjsDoughnut from "@core/components/charts/ChartjsDoughnut";
import DashBoardLeads from "@/views/dashboard/DashBoardLeads";
import axios from "@/libs/axios";
import UserCard from "@/views/dashboard/UserCard";
import NkDataTables from "@/libs/data-tables";

import "bootstrap"
import { useStore } from "vuex"
import { useRouter} from "vue-router"

export default defineComponent({
  name: 'Home',
  components: {
    UserCard,
    DashBoardLeads,
    ChartjsDoughnut,
    NkButton,
    Badge,
    NotificationItems,
    CardHeader,
    Card,
    Row,
    Column,
    BlockContent,
    MainBlock,
    NavBar,
    VerticalNavMenu,
    IonContent,
    IonPage,
    /*IonHeader,
    IonTitle,
    IonToolbar,*/
  },
  setup() {

    const router = useRouter()
    const store  = useStore()

    let dashboard_data = reactive({
      counts: {
        this_week: {
          total_leads: 0,
          avail_leads: 0,
        },
      },
      leads_statuses: [],
      leads: [],
      pie_chart: {
        labels: [],
        values: [],
        colors: [],
      },
    })
    axios.get('/api/advisor/dashboard')
    .then( (resp) => {
      for(let x in resp.data){
        dashboard_data[x] = resp.data[x];
      }
      nextTick(()=>{
        NkDataTables.dataTable(document.getElementById('dashboard_leads_table'), {})
      })
    })
    .catch( err => {
      console.log('Dashboard ajax error: ', err)
    })


    return {
      dashboard_data,
      router,
      store,
    }
  }
});
</script>
