<template>
  <card :no-padding="true" :full-height="true" id="user-card-at-dashboard">
    <div class="team">
      <div class="user-card user-card-s2 d-block p-0">
        <div class="user-banner" style="height: 158px;
    width: 100%;
    background-color: orange;">

        </div>
        <div class="user-avatar lg bg-primary shadow" style="position: absolute; margin-left: 2rem;transform: translateY(-50%);border: 4px solid;">
          <img v-if="user_data.dp" alt="DP" :src="user_data.dp"/>
          <span v-else>{{ user_data.initials }}</span>
          <div class="status dot dot-lg dot-success"></div>
        </div>
      </div>
      <row :no-gutters="true">
        <column :md="7" class="pt-5 pl-4 pb-3 mt-3">
          <span class="user-name d-block" style="font-size: 1.3rem;font-weight: 100;color: #333;">{{ user_data.first_name+' '+user_data.last_name }}</span>
          <span class="user-company d-block pt-1" style="color: #c0c0c9;">{{ user_data.company }}</span>
          <span class="user-street d-block">{{ user_data.address }}</span>
          <span class="user-post-code d-block">{{user_data.post_code+' '+user_data.city}}, {{ user_data.province }}</span>
          <badge :content="'AFM:'+user_data.afm_number" type="light"></badge>
        </column>
        <column :md="5" class="pt-3 pr-4 pl-2">
          <div class="d-flex justify-content-between border-bottom pb-1 mb-2 align-items-center">
            <div class="leads">
              <nio-icon icon="ni-users" color="#12d1e8"/><span class="ml-2">Leads</span>
            </div>
            <div class="text-muted">This week</div>
          </div>
          <div class="small font-weight-bold">
            <div class="mb-3">
              <div class="d-flex justify-content-between">
                <span>Leads</span><span class="text-success">{{ avail_leads+'/'+total_leads }}</span>
              </div>
              <div class="progress">
                <div class="progress-bar bg-success" role="progressbar" :style="{width: leads_progress+'%'}" :aria-valuenow="leads_progress" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
            </div>
            <div class="mb-2">
              <div class="d-flex justify-content-between">
                <span>Appointments</span><span>0/0</span>
              </div>
              <div class="progress">
                <div class="progress-bar" data-progress="25"></div>
              </div>
            </div>
          </div>
        </column>
      </row>
    </div><!-- .team -->
  </card>
</template>

<script>
import { Card } from '@core/components/cards'
import { Row, Column } from '@core/layouts';
import { Badge } from "@core/components"

export default {
  props: {
    user_data: {
      required: true,
      type: Object
    },
    total_leads: Number,
    avail_leads: Number,
  },
  components: {
    Badge,
    Card,
    Row,
    Column,
  },
  computed: {
    leads_progress(){
      let p = (this.avail_leads/this.total_leads) * 100
      return p.toFixed(2)
    }
  }
}
</script>
